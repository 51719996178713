import { CountUp } from 'countup.js'

const widgetToken = document.currentScript.dataset.widgetId
let countUp

const fetchImpact = success => {
  const url = `/widgets/impact/${widgetToken}.json`
  fetch(url, { headers: { Accept: 'application/json' } })
  .then(response => response.json()).then(success)
}

const refreshImpact = () =>
  fetchImpact(data => countUp.update(data.amount))

const initImpact = () => {
  const impactAmount = document.getElementById('home-impact-amount')
  const impactDesc = document.getElementById('home-impact-desc')

  fetchImpact(data => {
    countUp = new CountUp(impactAmount, data.amount * 1, {
      prefix: data.currency_symbol,
      startVal: data.amount * 0.95,
    })
    if (countUp.error)
      console.log(countUp.error)
    else {
      countUp.start()
      if (impactDesc) {
        impactDesc.textContent = data.description
        impactDesc.style.opacity = 1
      }
      impactAmount.style.opacity = 1
      setInterval(refreshImpact, 30000)
    }
  })
}

document.addEventListener('DOMContentLoaded', initImpact)
